#targetContainer .alert {
    min-width: 350px;
    line-height: 30px;
}

.upload-meta {
    position: relative;
}

.uppy-Root {
    font-family: 'Neue Aachen', Helvetica, Arial, sans-serif;
}

.uppy-DashboardItem-name,
.uppy-DashboardItem-status,
.uppy-StatusBar,
.uppy-StatusBar-statusSecondary {
    font-size: 13px;
}

.upload-meta

#uploadSuccess,
#uploadError {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 2000;
    border-radius: 10px;
}

form[name="channel"] {
    width: 100%;
}

.tagify.form-control {
    height: inherit;
}

.input-group.form-label-group label {
  z-index: 4;
}

.form-group-no-bottom-margin .form-group {
    margin-bottom: 0;
}

@media (min-width: 1200px) {
    .uppy-Root:not(.uppy-size--md) .uppy-StatusBar-additionalInfo {
     display: inline-block;
    }
}

.is-uploading .uppy-DashboardContent-addMore,
.is-uploading .uppy-DashboardItem-action--remove {
    display: none;
}