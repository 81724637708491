@font-face {
  font-family: 'Neue Aachen';
  src: url("/resources/fonts/NeueAachen.eot");
  src: url("/resources/fonts/NeueAachen.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/NeueAachen.woff") format("woff"), url("/resources/fonts/NeueAachen.ttf") format("truetype"); }
body {
    /*height: 100%;*/
    /*overflow: hidden; */
    font-family: 'Neue Aachen', Helvetica, Arial, sans-serif;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FFF;
    background-attachment: fixed;
}

.c-picture img {
    animation: fade 8s ease-in-out infinite;
    -webkit-animation: fade 90s linear infinite;
    height: 100vh;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0 0;
    object-position: 0 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.c-picture img:nth-of-type(1) {
    -webkit-animation-delay: 67.5s;
    animation-delay: 67.5s;
}
.c-picture img:nth-of-type(2) {
    -webkit-animation-delay: 45s;
    animation-delay: 45s;
}
.c-picture img:nth-of-type(3) {
    -webkit-animation-delay: 22.5s;
    animation-delay: 22.5s;
}
.c-picture img:nth-of-type(4) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@-webkit-keyframes fade {
    0% {
        opacity:1;
    }

    17% {
        opacity:1;
    }

    25% {
        opacity:0;
    }

    92% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@-moz-keyframes fade {
    0% {
        opacity:1;
    }

    17% {
        opacity:1;
    }

    25% {
        opacity:0;
    }

    92% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@-o-keyframes fade {
    0% {
        opacity:1;
    }

    17% {
        opacity:1;
    }

    25% {
        opacity:0;
    }

    92% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes fade {
    0% {
        opacity:1;
    }

    17% {
        opacity:1;
    }

    25% {
        opacity:0;
    }

    92% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

.navbar {
    padding: 1rem;
}

.badge {
    font-size: 100%;
}

.main-content-surface {
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.1), 0 10px 30px 0 rgba(0,0,0,0.2);
}

.container {
    position: relative;
}

.bg-light {
    background-color: #f6f7f6 !important;
}

.break-all {
    word-break: break-all;
}